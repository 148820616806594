import axios from './interceptor-axios';
import config from '~/config';

function getSimilarData(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiSimilar}/similarData`,
    params: {
      lang: params.lang,
      page: params.page || 1,
      per_page: params.per_page || 100,
      max_results: params.per_page || 100,
      disp_similar: params.disp_similar || false,
      order_by: params.order_by || '',
      service_type_for_similar:
        params.service_type_for_similar || config.serviceType,
      data_id: params.data_id,
      similar_max_results: params.similar_max_results || 10,
      same_creator: params.same_creator,
    },
  };

  const axiosInstance = axios.create();

  return axiosInstance(request).then((response) => response.data);
}

export { getSimilarData };
