export const SEARCH_PHOTO = 'containers/SearchResultPage/SEARCH_PHOTO';
export const SEARCH_PHOTO_REQUEST =
  'containers/SearchResultPage/SEARCH_PHOTO_REQUEST';
export const SEARCH_PHOTO_SUCCESS =
  'containers/SearchResultPage/SEARCH_PHOTO_SUCCESS';
export const SEARCH_PHOTO_ERROR =
  'containers/SearchResultPage/SEARCH_PHOTO_ERROR';

export const INIT_OTHER_SERVICE_SEARCH_RESULTS =
  'containers/SearchResultPage/INIT_OTHER_SERVICE_SEARCH_RESULTS';
export const SEARCH_OTHER_SERVICES =
  'containers/SearchResultPage/SEARCH_OTHER_SERVICES';
export const SEARCH_OTHER_SERVICES_REQUEST =
  'containers/SearchResultPage/SEARCH_OTHER_SERVICES_REQUEST';
export const SEARCH_OTHER_SERVICES_SUCCESS =
  'containers/SearchResultPage/SEARCH_OTHER_SERVICES_SUCCESS';
export const SEARCH_OTHER_SERVICES_ERROR =
  'containers/SearchResultPage/SEARCH_OTHER_SERVICES_ERROR';

export const FILTER_ORDER_BY = 'containers/SearchResultPage/FILTER_ORDER_BY';
export const FILTER_PER_PAGE = 'containers/SearchResultPage/FILTER_PER_PAGE';

export const CHANGE_PAGE = 'containers/SearchResultPage/CHANGE_PAGE';
export const CHANGE_PERSONALIZE =
  'containers/SearchResultPage/CHANGE_PERSONALIZE';
export const CHANGE_TOGGLE_FILTER_SEARCH =
  'containers/Search/CHANGE_TOGGLE_FILTER_SEARCH';
export const NICE_PHOTO_SUCCESS = 'containers/Search/NICE_PHOTO_SUCCESS';
export const NICE_PHOTO_ERROR = 'containers/Search/NICE_PHOTO_ERROR';
export const REMOVE_NICE_PHOTO_SUCCESS =
  'containers/Search/REMOVE_NICE_PHOTO_SUCCESS';
export const NICE_PHOTO = 'containers/Search/NICE_PHOTO';
