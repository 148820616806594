import FileSaver from 'file-saver';
import axios from './interceptor-axios';
import config from '~/config';
import Cookies from '~/services/Cookies';

function getDetailPhoto(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiDataDetail}/data/detail`,
    params: {
      data_id: params.id,
      lang: params.language || config.language,
      service_type: config.serviceType,
      clientIp: params.clientIp,
    },
  };
  return axios(request);
}

function getExtraLicensePhoto(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/data/current-extra-license`,
    params: {
      data_id: params.id,
      lang: params.language || config.language,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function downloadPhoto(params) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiDownload}/download`,
    data: {
      data_id: params.id,
      data_size: params.type,
      data_type: params.format ? params.format.toLowerCase() : params.type,
      service_type: config.serviceType,
      lang: config.language,
      currency: config.currency,
      paymentID: params.paymentID,
      payerID: params.payerID,
      paymentToken: params.paymentToken,
    },
  };
  return axios(request);
}

function saveImageWithUrl(url, name, type) {
  if (config.serviceType === 'photo_ac') {
    return FileSaver.saveAs(url, name);
  }
  return (window.location.href = url);
}

function nicePhoto(photoId) {
  const profile = JSON.parse(Cookies.get('profile') || '{}');
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiData}/nice`,
    data: {
      user_id: profile.id,
      data_id: photoId,
    },
  };

  return axios(request);
}
function removeNicePhoto(photoId) {
  const profile = JSON.parse(Cookies.get('profile') || '{}');
  const request = {
    method: 'DELETE',
    url: `${config.apiUrl.apiData}/nice`,
    data: {
      user_id: profile.id,
      data_id: photoId,
    },
  };

  return axios(request);
}
function getStatitics(photoId) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/photo/statitics`,
    params: {
      data_id: photoId,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function getSimilarData(params, lng) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiSimilar}/similarData`,
    params: {
      service_type: config.serviceType,
      lang: lng || config.language,
      page: params.page || 1,
      per_page: params.per_page || 10,
      max_results: params.per_page || 10,

      disp_similar: params.disp_similar || true,
      service_type_for_similar:
        params.service_type_for_similar || config.serviceType,
      data_id: params.id,
      // order_by           : filter['order_by'],
      similar_max_results: params.similar_max_results || 10,
    },
  };

  const axiosInstance = axios.create();
  return axiosInstance(request).then((response) => response);
}

function getRelatedData(dataID) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiSimilar}/related`,
    params: {
      data_id: dataID,
    },
  };
  return axios(request);
}

function getDataOfCategory(params, lang) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/category/data`,
    params: {
      page: params.page || 1,
      slug: params.category_slug,
      lang: lang || config.language,
      max_results: params.per_page || 10,
      per_page: params.per_page || 10,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function generateAIPeoplePhoto(params) {
  const request = {
    method: 'GET',
    // url: 'http://face-gen-elb-1982401916.us-west-2.elb.amazonaws.com/v1/genface',
    url: `${config.apiUrl.apiDownload}/ai/genface`,
    params: {
      v: 2, // 1: 256px, 2: 512px
    },
  };
  return axios(request);
}

function downloadAIPhoto(imageURL) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiDownload}/ai/download`,
    data: {
      image_url: imageURL,
    },
  };
  return axios(request);
}

function getCurrentStatusDownloadFromRedis(userId) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/user/download_status`,
    params: {
      user_id: userId,
      service_type: config.serviceType,
      // limit_number_show_capchar: 5,
    },
  };

  return axios(request);
}

function generateTokenForEditor(params) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiDownload}/editor/token`,
    data: {
      data_id: params.id,
      data_size: params.type,
      data_type: params.format ? params.format.toLowerCase() : params.type,
      service_type: config.serviceType,
      lang: config.language,
      message: params.message ? params.message : '',
    },
  };
  return axios(request);
}

function getPhotosForVoting() {
  const request = {
    method: 'GET',
    url: `${process.env.API_PHOTO}/photos/voting`,
    params: {
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function submitUserVote({ dataID, point, lang }) {
  const request = {
    method: 'POST',
    url: `${process.env.API_PHOTO}/photos/voting`,
    data: {
      service_type: config.serviceType,
      lang,
      data_id: dataID,
      point,
    },
  };
  return axios(request);
}

function checkBookmark(dataID) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/bookmark/data`,
    params: {
      service_type: config.serviceType,
      data_id: dataID,
    },
  };
  return axios(request);
}

function saveBookmark({ dataID, lng }) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiUser}/bookmark`,
    data: {
      service_type: config.serviceType,
      data_id: dataID,
      lang: lng,
    },
  };
  return axios(request);
}

export {
  submitUserVote,
  getExtraLicensePhoto,
  checkBookmark,
  getPhotosForVoting,
  generateTokenForEditor,
  getCurrentStatusDownloadFromRedis,
  downloadAIPhoto,
  generateAIPeoplePhoto,
  getDataOfCategory,
  removeNicePhoto,
  nicePhoto,
  saveImageWithUrl,
  downloadPhoto,
  getDetailPhoto,
  getRelatedData,
  getSimilarData,
  saveBookmark,
};
