import { i18n } from 'next-i18next';
import keyConfig from './keys';
import locales from '../utils/locales';
import { SERVICE_TYPES } from '~/utils/constants';

const GUEST = 'guest';
const FREE_MEMBER = 'free';
const PREMIUM = 'premium';
const CREATOR = 'creator';
const PREMIUM_NO_ADS = 'premium_no_ads';
const DESIGN_AC = 'design_ac';
const SILHOUETTE_AC = 'silhouette_ac';
const PHOTO_AC = 'photo_ac';
const ILLUST_AC = 'illust_ac';

const config = {
  env: process.env.ENV,
  ssoServerUrl: process.env.SSO_SERVER_URL,
  ssoAppToken: process.env.SSO_APP_TOKEN,
  apiUrl: {
    apiData: process.env.API_DATA,
    apiSimilar: process.env.API_SIMILAR,
    apiDataDetail: process.env.API_DATA_DETAIL,
    apiDonation: process.env.API_DONATION,
    apiDownload: process.env.API_DOWNLOAD,
    apiNotice: process.env.API_NOTICE,
    apiDashboard: process.env.API_DASHBOARD,
    apiPhoto: process.env.API_PHOTO,
    apiS3Service: process.env.API_S3_SERVICE,
    apiInitSearchImageService: process.env.API_INIT_SEARCH_IMAGE_SERVICE,
    apiSearchImageService: process.env.API_SEARCH_IMAGE_SERVICE,
    apiTemps: process.env.API_TEMPS,
    apiUser: process.env.API_USER,
    apiPublic: process.env.API_PUBLIC,
    apiDonationFreebie: process.env.API_DONATION_FREEBIE,
    apiSocket: process.env.API_SOCKET,
    apiSearch: process.env.API_SEARCH,
    apiSearchJapan: process.env.API_SEARCH_JAPAN,
    apiSearchPhotoAC: process.env.API_SEARCH_PHOTO_AC,
    apiSearchIllustAC: process.env.API_SEARCH_ILLUST_AC,
    apiSearchSilhouette: process.env.API_SEARCH_SILHOUETTE_AC,
    apiLogBehaviorIllust: process.env.API_LOG_BEHAVIOR_ILLUST,
    apiLogBehaviorPhoto: process.env.API_LOG_BEHAVIOR_PHOTO,
    apiDownloadComment: process.env.API_DOWNLOAD_COMMENT,
    getAPISearchByService(serviceType) {
      serviceType = serviceType || config.serviceType;
      if (serviceType === 'photo_ac') {
        return config.apiUrl.apiSearchPhotoAC;
      }
      if (serviceType === 'illust_ac') {
        return config.apiUrl.apiSearchIllustAC;
      }
      if (serviceType === 'silhouette_ac') {
        return config.apiUrl.apiSearchSilhouette;
      }
    },
  },
  assetPrefix: process.env.ASSET_PREFIX,
  staticPrefix: process.env.STATIC_PREFIX,
  stripePublicKey: {
    photo_ac: process.env.STRIPE_PUBLIC_KEY_PHOTO,
    illust_ac: process.env.STRIPE_PUBLIC_KEY_ILLUST,
    silhouette_ac: process.env.STRIPE_PUBLIC_KEY_SILHOUETTE,
    design_ac: process.env.STRIPE_PUBLIC_KEY_DESIGN,
  },
  clarifai: {
    api_key: process.env.CLARIFAI_API_KEY,
    languages: {
      tw: 'zh-TW',
      ko: 'ko',
      en: 'en',
    },
  },
  googleTranslate: {
    googleApiKey: process.env.GOOGLE_API_KEY,
    googleApi: process.env.GOOGLE_API,
  },
  serviceType: process.env.SERVICE_TYPE,
  language: process.env.LANGUAGE,
  facebookAppID: process.env.FACEBOOK_APP_ID,
  facebookUrlPage: process.env.FACEBOOK_URL_PAGE,
  googleClientID: process.env.GOOGLE_CLIENT_ID,
  googleCaptchaKey: process.env.GOOGLE_CAPTCHA_SITE_KEY,
  currency: process.env.CURRENCY,
  domainService: {
    templateAC: process.env.DESIGN_AC,
    photoAC: process.env.PHOTO_AC,
    illustAC: process.env.ILLUST_AC,
    silhouetteAC: process.env.SILHOUETTE_AC,
    editorAC: process.env.EDITOR_AC,
  },
  domainLinkByServiceType: {
    design_ac: process.env.DESIGN_AC,
    photo_ac: process.env.PHOTO_AC,
    illust_ac: process.env.ILLUST_AC,
    silhouette_ac: process.env.SILHOUETTE_AC,
  },
  chinaDomainLinkByServiceType: {
    design_ac: process.env.CHINA_DESIGN_AC || 'https://designac-cn.com',
    photo_ac: process.env.CHINA_PHOTO_AC || 'https://photoac-cn.com',
    illust_ac: process.env.CHINA_ILLUST_AC || 'https://illustac-cn.com',
    silhouette_ac:
      process.env.CHINA_SILHOUETTE_AC || 'https://silhouetteac-cn.com',
  },
  domainByServiceType: {
    photo_ac: process.env.DOMAIN_PHOTO_AC,
    illust_ac: process.env.DOMAIN_ILLUST_AC,
    silhouette_ac: process.env.DOMAIN_SILHOUETTE_AC,
    design_ac: process.env.DOMAIN_DESIGN_AC,
  },
  discount: {
    extraSeconds: parseInt(process.env.EXTRA_DISCOUNT_SECONDS || 300, 10),
  },
  detailPagePath: {
    design_ac: 'free-design-template',
    photo_ac: 'photo',
    illust_ac: 'clip-art',
    silhouette_ac: 'silhouette',
  },
  supportedLanguages: [
    'en',
    'de',
    'ar',
    'bn',
    'es',
    'fr',
    'it',
    'ko',
    'hi',
    'pl',
    'th',
    'tr',
    'ru',
    'vi',
    'tw',
    'cn',
    'jv',
    'pt',
    'en',
  ],
  supportedHrefLangs: [
    'en',
    'de',
    'ar',
    'bn',
    'es',
    'fr',
    'it',
    'ko',
    'hi',
    'pl',
    'th',
    'tr',
    'ru',
    'vi',
    'zh-Hant',
    'zh-Hans',
    'jv',
    'pt',
    'x-default',
    'ja',
  ],
  roles: {
    POWERPOINT: 'ppt',
    WEB_TEMPLATE: 'wtp',
    ADSENSE: 'ads',
    OTHER: 'other',
  },
  getFixedSizeByDataType: (dataType) => {
    const fixedHeights = {
      [config.roles.POWERPOINT]: 516,
      [config.roles.WEB_TEMPLATE]: 516,
      [config.roles.ADSENSE]: 516,
      [config.roles.OTHER]: 300,
    };

    const fixedWidths = {
      [config.roles.POWERPOINT]: 386,
      [config.roles.WEB_TEMPLATE]: 386,
      [config.roles.ADSENSE]: 386,
      [config.roles.OTHER]: null,
    };

    return {
      width: fixedWidths[dataType] || null,
      height: fixedHeights[dataType] || 244,
    };
  },

  listCoverImages: {
    design_ac: [
      {
        url: '/search/Global%20Business',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/illustAC/0-02.jpg`,
      },
      {
        url: '',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/illustAC/0-03.jpg`,
      },
      {
        url: '/search/Real%20universe',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/illustAC/0-04.jpg`,
      },
      {
        url: '/search/yoga?orderBy=newest&page=1&per_page=100',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/illustAC/0-05.jpg`,
      },
    ],
    photo_ac: [
      {
        url: '/photo/22243',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-01.jpg`,
      },
      {
        url: '/photo/35884',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-02.jpg`,
      },
      {
        url: '/photo/16355',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-03.jpg`,
      },
      {
        url: '/photo/27442',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-04.jpg`,
      },
      {
        url: '/photo/1283175',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-05.jpg`,
      },
      {
        url: '/photo/40431',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-06.jpg`,
      },
      {
        url: '/photo/27680',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-07.jpg`,
      },
      {
        url: '/photo/67649',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-08.jpg`,
      },
      {
        url: '/photo/24666',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-09.jpg`,
      },
      {
        url: '/photo/1005895',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-10.jpg`,
      },
      {
        url: '/photo/52832',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-11.jpg`,
      },
      {
        url: '/photo/1153275',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-12.jpg`,
      },
      {
        url: '/photo/14603',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-13.jpg`,
      },
      {
        url: '/photo/465353',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/photoAC/0-14.jpg`,
      },
    ],
    silhouette_ac: [
      {
        url: '/search/building?page=1',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/silhouetteAC/0-01.jpg`,
      },
      {
        url: '/search/animal?page=1',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/silhouetteAC/0-02.jpg`,
      },
      {
        url: '/search/factory?page=1',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/silhouetteAC/0-03.jpg`,
      },
      {
        url: '/search/yoga?page=1',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/silhouetteAC/0-04.jpg`,
      },
      {
        url: '/search/bread?page=1',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/silhouetteAC/0-05.jpg`,
      },
      {
        url: '/search/rain?page=1',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/silhouetteAC/0-06.jpg`,
      },
    ],
    illust_ac: [
      {
        url: '/search/Global%20Business',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/illustAC/0-02.jpg`,
      },
      {
        url: '',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/illustAC/0-03.jpg`,
      },
      {
        url: '/search/Real%20universe',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/illustAC/0-04.jpg`,
      },
      {
        url: '/search/yoga&orderBy=newest&page=1&per_page=100',
        image: `${process.env.STATIC_PREFIX}/static/assets/image/header/illustAC/0-05.jpg`,
      },
    ],
  },
  getFileAccept() {
    if (config.isPhotoAC()) {
      return {
        accept: '.psd, .jpg, .jpeg',
        types: ['JPEG', 'PSD'],
        content: 'form_upload_content_1',
      };
    }

    if (config.isIllustAC()) {
      return {
        accept:
          'application/illustrator,application/postscript,image/svg+xml,image/jpeg,image/png',
        types: ['JPEG', 'PNG', 'AI', 'EPS', 'SVG'],
        content: 'form_upload_content_illust_1',
      };
    }

    if (config.isSilhouetteAC()) {
      return {
        accept:
          'application/illustrator,application/postscript,image/svg+xml,image/jpeg,image/png',
        types: ['JPEG', 'PNG', 'AI', 'EPS', 'SVG'],
        content: 'form_upload_content_illust_1',
      };
    }

    return {
      accept: 'application/zip,image/jpeg,image/png',
      types: ['ZIP', 'PNG', 'JPEG'],
      content: 'form_upload_content_illust_1',
    };
  },
  totalData: {
    photo_ac: 2137992,
    illust_ac: 1256609,
    silhouette_ac: 66333,
    design_ac: 1091,
  },
  getOthersSite(currentLanguage = 'en') {
    const ITEM_PHOTO = {
      name: 'stock_photos',
      serviceName: 'photoAC',
      icon: 'photo',
      link: config.isChina()
        ? process.env.PHOTO_AC
        : `${process.env.PHOTO_AC}/${currentLanguage}`,
      serviceType: 'photo_ac',
    };

    const ITEM_SILHOUETTE = {
      name: 'silhouettes',
      serviceName: 'silhouetteAC',
      icon: 'silhouette',
      link: config.isChina()
        ? process.env.SILHOUETTE_AC
        : `${process.env.SILHOUETTE_AC}/${currentLanguage}`,
      serviceType: 'silhouette_ac',
    };

    const ITEM_ILLUST = {
      name: 'vectors',
      serviceName: 'illustAC',
      icon: 'illust',
      link: config.isChina()
        ? process.env.ILLUST_AC
        : `${process.env.ILLUST_AC}/${currentLanguage}`,
      serviceType: 'illust_ac',
    };

    const ITEM_DESIGN = {
      name: 'templates',
      serviceName: 'templateAC',
      icon: 'design',
      link: config.isChina()
        ? process.env.DESIGN_AC
        : `${process.env.DESIGN_AC}/${currentLanguage}`,
      serviceType: 'design_ac',
      // icon: true,
    };

    switch (config.serviceType) {
      case 'silhouette_ac':
        return [ITEM_PHOTO, ITEM_ILLUST, ITEM_DESIGN, ITEM_SILHOUETTE];
      case 'illust_ac':
        return [ITEM_PHOTO, ITEM_ILLUST, ITEM_DESIGN, ITEM_SILHOUETTE];
      case 'design_ac':
        return [ITEM_PHOTO, ITEM_ILLUST, ITEM_DESIGN, ITEM_SILHOUETTE];
      default:
        return [ITEM_PHOTO, ITEM_ILLUST, ITEM_DESIGN, ITEM_SILHOUETTE];
    }
  },

  getRoles() {
    return {
      GUEST,
      FREE_MEMBER,
      PREMIUM,
      CREATOR,
      PREMIUM_NO_ADS,
    };
  },
  getServiceTypes() {
    return {
      DESIGN_AC,
      ILLUST_AC,
      PHOTO_AC,
      SILHOUETTE_AC,
    };
  },
  getListDomain(currentLanguage = 'en') {
    if (config.isChina()) {
      return {
        DESIGN_AC: `${process.env.DESIGN_AC}`,
        PHOTO_AC: `${process.env.PHOTO_AC}`,
        ILLUST_AC: `${process.env.ILLUST_AC}`,
        SILHOUETTE_AC: `${process.env.SILHOUETTE_AC}`,
      };
    }
    return {
      DESIGN_AC: `${process.env.DESIGN_AC}/${currentLanguage}`,
      PHOTO_AC: `${process.env.PHOTO_AC}/${currentLanguage}`,
      ILLUST_AC: `${process.env.ILLUST_AC}/${currentLanguage}`,
      SILHOUETTE_AC: `${process.env.SILHOUETTE_AC}/${currentLanguage}`,
    };
  },
  renderPrefixIconCate: () => {
    switch (config.serviceType.toUpperCase()) {
      case 'PHOTO_AC':
        return 'photo';
      case 'ILLUST_AC':
        return 'illust';
      case 'DESIGN_AC':
        return 'design';
      default:
        return 'sil';
    }
  },
  getPrefix(serviceType) {
    switch (serviceType) {
      case SERVICE_TYPES.ILLUST_AC:
        return 'ill';
      case SERVICE_TYPES.PHOTO_AC:
        return 'photo';
      case SERVICE_TYPES.SILHOUETTE_AC:
        return 'sil';
      default:
        return 'design';
    }
  },
  getCurrencyIcon(currency) {
    if (!currency) {
      return '';
    }
    switch (currency.toUpperCase()) {
      case 'TWD':
        return '$';
      case 'KRW':
        return '₩';
      case 'USD':
        return '$';
      case 'VND':
        return '₫';
      case 'THB':
        return '฿';
      case 'HKD':
        return '$';
      case 'CNY':
        return '¥';
    }
  },
  capitalizeFirstLetter: (string = '') =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase(),
  getTitleByLanguage: (title, currentLanguage) => {
    if (currentLanguage === 'tw') currentLanguage = 'zh-tw';
    if (currentLanguage === 'cn') currentLanguage = 'zh-cn';

    let titleParse = title;
    if (typeof title === 'string') {
      try {
        titleParse = JSON.parse(title) || {};
      } catch (e) {
        return '';
      }
    }
    if (!titleParse) return '';

    if (currentLanguage === 'zh-cn') {
      return (
        titleParse['title_zh-cn'] ||
        titleParse['title_zh-tw'] ||
        titleParse['title_en'] ||
        ''
      );
    }
    const outputTitle =
      titleParse[`title_${currentLanguage}`] || titleParse['title_en'] || '';
    if (
      (currentLanguage === 'en' || currentLanguage === 'vi') &&
      locales.hasCJKCharacters(outputTitle)
    ) {
      return '';
    }
    return outputTitle;
  },
  getPrefixContent: (content) =>
    `${config.getPrefix(config.serviceType)}_${content}`,
  getDetailTagLinkByServiceType: () => {
    switch (process.env.SERVICE_TYPE) {
      case 'design_ac':
        return 'free-template';
      case 'illust_ac':
        return 'free-vector';
      case 'silhouette_ac':
        return 'free-silhouette';
      case 'photo_ac':
        return 'free-photo';
    }
  },

  getDataTypeOfDesignCategories: (catId) => {
    let dataTypes = '';

    switch (catId) {
      case 15:
        dataTypes = 'PPT';
        break;

      case 7:
      case 4:
      case 5:
      case 16:
        dataTypes = 'PSD, PNG, JPG';
        break;
      case 12:
        dataTypes = 'ABR, AI, PSD, PNG, JPG';
        break;
      default:
        dataTypes = 'AI, PSD, PNG, JPG';
        break;
    }

    return dataTypes;
  },

  renderDownloadPageByServiceAndLanguage: (lang, pathDownload) => {
    let downloadRoutes = {
      design_ac: 'free-design-template',
      photo_ac: 'photo',
      illust_ac: 'clip-art',
      silhouette_ac: 'silhouette',
    };
    const siteURL = config.getSiteURL(process.env.SERVICE_TYPE, lang);
    return `${siteURL}/${
      downloadRoutes[process.env.SERVICE_TYPE]
    }/${pathDownload}`;

    // if (process.env.LANGUAGE === 'cn') {
    //   switch (process.env.SERVICE_TYPE) {
    //     case 'design_ac':
    //       return `${process.env.CHINA_DESIGN_AC}${lang}/${downloadRoutes['design_ac']}/${pathDownload}`;
    //     case 'illust_ac':
    //       return `${process.env.CHINA_ILLUST_AC}${lang}/${downloadRoutes['illust_ac']}/${pathDownload}`;
    //     case 'silhouette_ac':
    //       return `${process.env.CHINA_SILHOUETTE_AC}${lang}/${downloadRoutes['silhouette_ac']}/${pathDownload}`;
    //     case 'photo_ac':
    //       return `${process.env.CHINA_PHOTO_AC}${lang}/${downloadRoutes['photo_ac']}/${pathDownload}`;
    //   }
    // } else {
    //   switch (process.env.SERVICE_TYPE) {
    //     case 'design_ac':
    //       return `${process.env.DESIGN_AC}${lang}/${downloadRoutes['design_ac']}/${pathDownload}`;
    //     case 'illust_ac':
    //       return `${process.env.ILLUST_AC}${lang}/${downloadRoutes['illust_ac']}/${pathDownload}`;
    //     case 'silhouette_ac':
    //       return `${process.env.SILHOUETTE_AC}${lang}/${downloadRoutes['silhouette_ac']}/${pathDownload}`;
    //     case 'photo_ac':
    //       return `${process.env.PHOTO_AC}${lang}/${downloadRoutes['photo_ac']}/${pathDownload}`;
    //   }
    // }
  },

  renderTitleOpenSearch: () => {
    // eslint-disable-next-line default-case
    switch (process.env.SERVICE_TYPE) {
      case 'design_ac':
        return 'templateAC';
      case 'illust_ac':
        return 'illustAC';
      case 'silhouette_ac':
        return 'silhouetteAC';
      case 'photo_ac':
        return 'photoAC';
    }
  },

  getTagsByLanguage: (item = {}, currentLanguage) => {
    if (currentLanguage == 'tw') currentLanguage = 'zh-tw';
    if (currentLanguage == 'cn') currentLanguage = 'zh-cn';
    return item[`name_${currentLanguage}`];
  },
  isChina: () => {
    return ['cn', 'zh-cn'].indexOf(process.env.LANGUAGE) > -1;
  },
  getTitleLengthDefine: (lang) => {
    let title = {};
    if (config.isPhotoAC()) {
      title = {
        en: 26,
      };
    }
    if (config.isIllustAC()) {
      title = {
        en: 30,
      };
    }
    if (config.isSilhouetteAC()) {
      title = {
        en: 37,
      };
    }
    if (config.isDesignAC()) {
      title = {
        en: 37,
      };
    }
    return title[lang];
  },

  getValueByLanguage: (item = {}, name, lang) => {
    if (!item[`${name}_${lang}`] && lang === 'jp') {
      return item[`${name}_jp`];
    }
    return item[`${name}_${lang}`];
  },
  getTagTitleByCharacter: (tags = [], id = '', char = ', ', lang = 'en') => {
    let words = '';
    const numberWord = config.getTitleLengthDefine(lang);
    if (!numberWord) {
      return tags
        .slice(0, 5)
        .map((tag) => config.getValueByLanguage(tag, 'name', lang))
        .join(char);
    }

    const min = 55;
    const max = 66;
    for (let i = 0; i < (tags || []).length; i++) {
      const tagName = config.getValueByLanguage(tags[i], 'name', lang) || '';
      if (
        words.length === max ||
        numberWord + words.length + tagName.length + id.toString().length > max
      ) {
        break;
      }
      words += `${tagName}%:`;
    }
    return words
      .split('%:')
      .filter((word) => !!word)
      .join(char);
  },

  getDesLengthDefine: (lang) => {
    let des = {};
    if (config.isDesignAC()) {
      des = {
        en: 81,
      };
    }
    if (config.isPhotoAC()) {
      des = {
        en: 81,
      };
    }
    if (config.isIllustAC()) {
      des = {
        en: 109,
      };
    }
    if (config.isSilhouetteAC()) {
      des = {
        en: 103,
      };
    }
    return des[lang];
  },
  getTagDesByCharacter: (tags = [], char = ', ', lang = 'en') => {
    let words = '';
    const numberWord = config.getDesLengthDefine(lang);

    if (!numberWord) {
      return tags
        .slice(0, 5)
        .map((tag) => config.getValueByLanguage(tag, 'name', lang))
        .join(char);
    }

    const min = 140;
    const max = 170;
    for (let i = 0; i < (tags || []).length; i++) {
      const tagName = config.getValueByLanguage(tags[i], 'name', lang) || '';
      if (
        words.length === max ||
        numberWord + words.length + tagName.length > max
      ) {
        break;
      }
      words += `${tagName}%:`;
    }
    return words
      .split('%:')
      .filter((word) => !!word)
      .join(char);
  },
  getName: () => config.serviceType.toUpperCase(),
  getWebPushPublicKey() {
    return process.env.WEB_PUSH_PUBLIC_KEY;
  },
  getStatusNoticeCookie() {
    return isNaN(config.getCookieStatus())
      ? 0
      : config.setCookie('hide_notice_cookie', 1, 31536000);
  },
  getCookie(cname) {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },
  updateStatusNoticeCookie(bool) {
    return (document.cookie = `hide_notice_cookie=${bool}`);
  },

  setCookie(cookieName, cookieValue, maxAge, path = '/') {
    document.cookie = `${cookieName}=${cookieValue}; max-age=${maxAge}; path=${path};`;
  },

  getCookieStatus() {
    const cookie = config.getCookie('hide_notice_cookie');
    return cookie;
  },
  renderImageShareTwitter: () => {
    if (config.serviceType === 'photo_ac') {
      return 'photoac_image_share.png';
    }

    if (config.serviceType === 'illust_ac') {
      return 'illust.png';
    }

    if (config.serviceType === 'silhouette_ac') {
      return 'silhouette.png';
    }

    if (config.serviceType === 'design_ac') {
      return 'silhouette.png';
    }
  },
  renderImagePage: () => {
    if (config.serviceType === 'photo_ac') {
      return 'photo_open_graph.jpeg';
    }

    if (config.serviceType === 'illust_ac') {
      return 'illust_open_graph.jpg';
    }

    if (config.serviceType === 'silhouette_ac') {
      return 'logo_silhouetteAc.svg';
    }

    if (config.serviceType === 'design_ac') {
      return 'design.png';
    }
  },
  getEnvGoogleAnalyticsByLanguagePhoto: (locale) => {
    const lang = locale.toUpperCase();
    const langAnalytics = {
      AR: process.env.PHOTO_GOOGLE_ANALYTICS_AR,
      RU: process.env.PHOTO_GOOGLE_ANALYTICS_RU,
      TH: process.env.PHOTO_GOOGLE_ANALYTICS_TH,
      TW: process.env.PHOTO_GOOGLE_ANALYTICS_TW,
      FR: process.env.PHOTO_GOOGLE_ANALYTICS_FR,
      HI: process.env.PHOTO_GOOGLE_ANALYTICS_HI,
      PL: process.env.PHOTO_GOOGLE_ANALYTICS_PL,
      VI: process.env.PHOTO_GOOGLE_ANALYTICS_VI,
      JV: process.env.PHOTO_GOOGLE_ANALYTICS_JV,
      ES: process.env.PHOTO_GOOGLE_ANALYTICS_ES,
      PT: process.env.PHOTO_GOOGLE_ANALYTICS_PT,
      KO: process.env.PHOTO_GOOGLE_ANALYTICS_KO,
      IT: process.env.PHOTO_GOOGLE_ANALYTICS_IT,
      DE: process.env.PHOTO_GOOGLE_ANALYTICS_DE,
      CN: process.env.PHOTO_GOOGLE_ANALYTICS_CN,
      BN: process.env.PHOTO_GOOGLE_ANALYTICS_BN,
      TR: process.env.PHOTO_GOOGLE_ANALYTICS_TR,
    };
    return langAnalytics[lang] || process.env.PHOTO_GOOGLE_ANALYTICS;
  },
  getEnvGoogleAnalyticsByLanguageIllust: (locale) => {
    console.log('getEnvGoogleAnalyticsByLanguageIllust lang', locale);
    const lang = locale.toUpperCase();
    const langAnalytics = {
      AR: process.env.ILL_GOOGLE_ANALYTICS_AR,
      RU: process.env.ILL_GOOGLE_ANALYTICS_RU,
      TH: process.env.ILL_GOOGLE_ANALYTICS_TH,
      TW: process.env.ILL_GOOGLE_ANALYTICS_TW,
      FR: process.env.ILL_GOOGLE_ANALYTICS_FR,
      HI: process.env.ILL_GOOGLE_ANALYTICS_HI,
      PL: process.env.ILL_GOOGLE_ANALYTICS_PL,
      VI: process.env.ILL_GOOGLE_ANALYTICS_VI,
      JV: process.env.ILL_GOOGLE_ANALYTICS_JV,
      ES: process.env.ILL_GOOGLE_ANALYTICS_ES,
      PT: process.env.ILL_GOOGLE_ANALYTICS_PT,
      KO: process.env.ILL_GOOGLE_ANALYTICS_KO,
      IT: process.env.ILL_GOOGLE_ANALYTICS_IT,
      DE: process.env.ILL_GOOGLE_ANALYTICS_DE,
      CN: process.env.ILL_GOOGLE_ANALYTICS_CN,
      BN: process.env.ILL_GOOGLE_ANALYTICS_BN,
      TR: process.env.ILL_GOOGLE_ANALYTICS_TR,
    };
    return langAnalytics[lang] || process.env.ILL_GOOGLE_ANALYTICS;
  },
  getGoogleAnalytics: (locale) => {
    if (config.serviceType === 'photo_ac') {
      // console.log('key ne', config.getEnvGoogleAnalyticsByLanguagePhoto());
      return config.getEnvGoogleAnalyticsByLanguagePhoto(locale);
    }

    if (config.serviceType === 'illust_ac') {
      // return process.env.ILL_GOOGLE_ANALYTICS;
      console.log('locale hien tai ne', locale);
      console.log(
        'key ne',
        config.getEnvGoogleAnalyticsByLanguageIllust(locale),
      );

      return config.getEnvGoogleAnalyticsByLanguageIllust(locale);
    }

    if (config.serviceType === 'silhouette_ac') {
      console.log(
        'day la ngon ngu hien tai trong ga',
        config.language.toUpperCase(),
      );
      return process.env.SIL_GOOGLE_ANALYTICS;
    }

    if (config.serviceType === 'design_ac') {
      return process.env.DESIGN_GOOGLE_ANALYTICS;
    }
  },
  avatars: {
    photo_ac: [
      '/static/images/avatars/f1.png',
      '/static/images/avatars/f2.png',
      '/static/images/avatars/f3.png',
      '/static/images/avatars/f4.png',
      '/static/images/avatars/f5.png',
      '/static/images/avatars/f6.png',
      '/static/images/avatars/f7.png',
      '/static/images/avatars/f8.png',
      '/static/images/avatars/f9.png',
      '/static/images/avatars/f10.png',
      '/static/images/avatars/f11.png',
      '/static/images/avatars/f12.png',
      '/static/images/avatars/f13.png',
      '/static/images/avatars/f14.png',
      '/static/images/avatars/f15.png',
      '/static/images/avatars/f16.png',
      '/static/images/avatars/f17.png',
      '/static/images/avatars/f18.png',
      '/static/images/avatars/f19.png',
      '/static/images/avatars/f20.png',
      '/static/images/avatars/f21.png',
      '/static/images/avatars/f22.png',
      '/static/images/avatars/f23.png',
      '/static/images/avatars/f24.png',
      '/static/images/avatars/f25.png',
      '/static/images/avatars/f26.png',
      '/static/images/avatars/f27.png',
      '/static/images/avatars/f28.png',
      '/static/images/avatars/f29.png',
      '/static/images/avatars/f30.png',
    ],
    illust_ac: [
      '/static/images/avatars/f1.png',
      '/static/images/avatars/f2.png',
      '/static/images/avatars/f3.png',
      '/static/images/avatars/f4.png',
      '/static/images/avatars/f5.png',
      '/static/images/avatars/f6.png',
      '/static/images/avatars/f7.png',
      '/static/images/avatars/f8.png',
      '/static/images/avatars/f9.png',
      '/static/images/avatars/f10.png',
      '/static/images/avatars/f11.png',
      '/static/images/avatars/f12.png',
      '/static/images/avatars/f13.png',
      '/static/images/avatars/f14.png',
      '/static/images/avatars/f15.png',
      '/static/images/avatars/f16.png',
      '/static/images/avatars/f17.png',
      '/static/images/avatars/f18.png',
      '/static/images/avatars/f19.png',
      '/static/images/avatars/f20.png',
      '/static/images/avatars/f21.png',
      '/static/images/avatars/f22.png',
      '/static/images/avatars/f23.png',
      '/static/images/avatars/f24.png',
      '/static/images/avatars/f25.png',
      '/static/images/avatars/f26.png',
      '/static/images/avatars/f27.png',
      '/static/images/avatars/f28.png',
      '/static/images/avatars/f29.png',
      '/static/images/avatars/f30.png',
    ],
    silhouette_ac: [
      '/static/images/avatars/f1.png',
      '/static/images/avatars/f2.png',
      '/static/images/avatars/f3.png',
      '/static/images/avatars/f4.png',
      '/static/images/avatars/f5.png',
      '/static/images/avatars/f6.png',
      '/static/images/avatars/f7.png',
      '/static/images/avatars/f8.png',
      '/static/images/avatars/f9.png',
      '/static/images/avatars/f10.png',
      '/static/images/avatars/f11.png',
      '/static/images/avatars/f12.png',
      '/static/images/avatars/f13.png',
      '/static/images/avatars/f14.png',
      '/static/images/avatars/f15.png',
      '/static/images/avatars/f16.png',
      '/static/images/avatars/f17.png',
      '/static/images/avatars/f18.png',
      '/static/images/avatars/f19.png',
      '/static/images/avatars/f20.png',
      '/static/images/avatars/f21.png',
      '/static/images/avatars/f22.png',
      '/static/images/avatars/f23.png',
      '/static/images/avatars/f24.png',
      '/static/images/avatars/f25.png',
      '/static/images/avatars/f26.png',
      '/static/images/avatars/f27.png',
      '/static/images/avatars/f28.png',
      '/static/images/avatars/f29.png',
      '/static/images/avatars/f30.png',
    ],
    design_ac: [
      '/static/images/avatars/f1.png',
      '/static/images/avatars/f2.png',
      '/static/images/avatars/f3.png',
      '/static/images/avatars/f4.png',
      '/static/images/avatars/f5.png',
      '/static/images/avatars/f6.png',
      '/static/images/avatars/f7.png',
      '/static/images/avatars/f8.png',
      '/static/images/avatars/f9.png',
      '/static/images/avatars/f10.png',
      '/static/images/avatars/f11.png',
      '/static/images/avatars/f12.png',
      '/static/images/avatars/f13.png',
      '/static/images/avatars/f14.png',
      '/static/images/avatars/f15.png',
      '/static/images/avatars/f16.png',
      '/static/images/avatars/f17.png',
      '/static/images/avatars/f18.png',
      '/static/images/avatars/f19.png',
      '/static/images/avatars/f20.png',
      '/static/images/avatars/f21.png',
      '/static/images/avatars/f22.png',
      '/static/images/avatars/f23.png',
      '/static/images/avatars/f24.png',
      '/static/images/avatars/f25.png',
      '/static/images/avatars/f26.png',
      '/static/images/avatars/f27.png',
      '/static/images/avatars/f28.png',
      '/static/images/avatars/f29.png',
      '/static/images/avatars/f30.png',
    ],
  },
  headers: {
    photo_ac: [
      '/static/images/headers/photo_ac/0-1.jpg',
      '/static/images/headers/photo_ac/0-2.jpg',
      '/static/images/headers/photo_ac/0-3.jpg',
      '/static/images/headers/photo_ac/0-4.jpg',
      '/static/images/headers/photo_ac/0-5.jpg',
      '/static/images/headers/photo_ac/0-6.jpg',
      '/static/images/headers/photo_ac/0-7.jpg',
      '/static/images/headers/photo_ac/0-8.jpg',
      '/static/images/headers/photo_ac/0-9.jpg',
      '/static/images/headers/photo_ac/0-10.jpg',
      '/static/images/headers/photo_ac/0-11.jpg',
      '/static/images/headers/photo_ac/0-12.jpg',
      '/static/images/headers/photo_ac/0-13.jpg',
      '/static/images/headers/photo_ac/0-14.jpg',
    ],
    illust_ac: [
      '/static/images/headers/illust_ac/0-1.jpg',
      '/static/images/headers/illust_ac/0-2.jpg',
      '/static/images/headers/illust_ac/0-3.jpg',
      '/static/images/headers/illust_ac/0-4.jpg',
      '/static/images/headers/illust_ac/0-5.jpg',
      '/static/images/headers/illust_ac/0-6.jpg',
    ],
    silhouette_ac: [
      '/static/images/headers/silhouette_ac/0-1.jpg',
      '/static/images/headers/silhouette_ac/0-2.jpg',
      '/static/images/headers/silhouette_ac/0-3.jpg',
      '/static/images/headers/silhouette_ac/0-4.jpg',
      '/static/images/headers/silhouette_ac/0-5.jpg',
      '/static/images/headers/silhouette_ac/0-6.jpg',
    ],
    design_ac: [],
  },
  getAvatars: () => config.avatars[config.serviceType],
  getHeaders: () => config.headers[config.serviceType],
  getSiteURL: (serviceType, language) => {
    // Site china
    if (config.isChina() || language === 'cn') {
      return config.chinaDomainLinkByServiceType[
        serviceType || config.serviceType
      ];
    }

    // Site global
    language = language || i18n.language;
    serviceType = serviceType || config.serviceType;

    if (serviceType === config.serviceType) {
      if (process.env.DOMAIN) {
        // Use subdomain by language
        if (language === 'tw') {
          language = 'zh-tw';
        }
        return `https://${language}.${process.env.DOMAIN}`;
      }
      return `${config.domainLinkByServiceType[serviceType]}/${language}`;
    }

    if (language === 'tw') {
      language = 'zh-tw';
    }
    return `https://${language}.${config.domainByServiceType[serviceType]}`;
  },
  getDomainLink: (serviceType) => {
    serviceType = serviceType || config.serviceType;
    if (config.isChina()) {
      return config.chinaDomainLinkByServiceType[serviceType];
    }
    return config.domainLinkByServiceType[serviceType];
  },
  getDomainByService: () => {
    if (config.serviceType === 'photo_ac') {
      return process.env.PHOTO_AC;
    }

    if (config.serviceType === 'illust_ac') {
      return process.env.ILLUST_AC;
    }

    if (config.serviceType === 'silhouette_ac') {
      return process.env.SILHOUETTE_AC;
    }

    if (config.serviceType === 'design_ac') {
      return process.env.DESIGN_AC;
    }
  },
  getJapanDomainByService: () => {
    if (config.serviceType === 'photo_ac') {
      return 'https://www.photo-ac.com';
    }

    if (config.serviceType === 'illust_ac') {
      return 'https://www.ac-illust.com';
    }

    if (config.serviceType === 'silhouette_ac') {
      return 'https://www.silhouette-ac.com';
    }
  },
  getBaiduAnalytics: () => {
    if (config.serviceType === 'photo_ac') {
      return process.env.PHOTO_BAIDU_ANALYTICS;
    }

    if (config.serviceType === 'illust_ac') {
      return process.env.ILL_BAIDU_ANALYTICS;
    }

    if (config.serviceType === 'silhouette_ac') {
      return process.env.SIL_BAIDU_ANALYTICS;
    }

    if (config.serviceType === 'design_ac') {
      return process.env.DESIGN_BAIDU_ANALYTICS;
    }
  },
  isUsingGridGallery: (dataType) =>
    dataType === config.roles.POWERPOINT ||
    dataType === config.roles.WEB_TEMPLATE ||
    dataType === config.roles.ADSENSE,

  isPhotoAC: () => config.serviceType === SERVICE_TYPES.PHOTO_AC,
  isIllustAC: () => config.serviceType === SERVICE_TYPES.ILLUST_AC,
  isSilhouetteAC: () => config.serviceType === SERVICE_TYPES.SILHOUETTE_AC,
  isDesignAC: () => config.serviceType === SERVICE_TYPES.DESIGN_AC,

  creatorAvailableLanguages: process.env.ENV === 'production' ? ['tw'] : ['tw'],
  creatorAvailableServices: [SERVICE_TYPES.PHOTO_AC, SERVICE_TYPES.ILLUST_AC],
  isCreatorAvailable: (language, serviceType) => {
    language = language || config.language;
    serviceType = serviceType || config.serviceType;
    const isValidService = config.creatorAvailableServices.includes(
      serviceType,
    );
    const isValidLanguage = config.creatorAvailableLanguages.includes(language);
    return isValidService && isValidLanguage;
  },

  renderLink: (currentLang) => {
    const LINK_TW = {
      link_fb: 'https://www.facebook.com/TW.ACworks/',
      link_twitter: 'https://twitter.com/tw_ACworks',
    };
    const LINK_EN = {
      link_fb: 'https://www.facebook.com/enACworks/',
      link_twitter: 'https://twitter.com/en_ACworks',
    };
    const LINK_KO = {
      link_fb: 'https://www.facebook.com/ACworksKorea/',
      link_twitter: 'https://twitter.com/ACworks_Korea',
    };
    const LINK_TH = {
      link_fb: 'https://www.facebook.com/ACworksThailand/',
      link_twitter: 'https://twitter.com/th_ACworks',
    };
    const LINK_DEFAULT = {
      link_fb: 'https://www.facebook.com/enACworks',
      link_twitter: 'https://twitter.com/en_ACworks',
    };

    switch (currentLang) {
      case 'tw':
        return LINK_TW;
      case 'ko':
        return LINK_KO;
      case 'en':
        return LINK_EN;
      case 'th':
        return LINK_TH;
      default:
        return LINK_DEFAULT;
    }
  },
  logoGray: {
    photo_ac: 'photo_logo_mypage.png',
    illust_ac: 'illust_logo_mypage.png',
    silhouette_ac: 'silhouette_logo_mypage.png',
    design_ac: 'design_logo_mypage.png',
  },
  renderLinkBlog: (currentLang) => {
    if (currentLang === 'tw') {
      if (config.isPhotoAC()) {
        return 'https://tw.blog.acworks.co.jp/category/photoac/';
      }
      if (config.isIllustAC()) {
        return 'https://tw.blog.acworks.co.jp/category/illustac/';
      }
      if (config.isSilhouetteAC()) {
        return 'https://tw.blog.acworks.co.jp/category/silhouetteac/';
      }
      return 'http://tw.blog.acworks.co.jp';
    } else {
      if (config.isPhotoAC()) {
        return 'https://www.photo-ac.com/blog/';
      }
      if (config.isIllustAC()) {
        return 'https://www.ac-illust.com/blog/';
      }
      return 'http://en.blog.acworks.co.jp';
    }
  },
  getLogo: (serviceType) => {
    const logoInfos = {
      [SERVICE_TYPES.PHOTO_AC]: {
        mobile: `${config.staticPrefix}/static/ico/favicon.photo_ac.png`,
        desktop: `${config.staticPrefix}/static/assets/image/logo/logo_photo_ac.svg`,
        descriptionKey: 'free_stock_photos_ac',
        logoNoText: `${config.staticPrefix}/static/assets/image/logo/logo_photo_no_text.svg`,
      },
      [SERVICE_TYPES.ILLUST_AC]: {
        mobile: `${config.staticPrefix}/static/ico/favicon.illust_ac.png`,
        desktop: `${config.staticPrefix}/static/assets/image/logo/logo_illust.svg`,
        descriptionKey: 'free_vector_clip_art',
        logoNoText: `${config.staticPrefix}/static/assets/image/logo/logo_illust_no_text.svg`,
      },
      [SERVICE_TYPES.SILHOUETTE_AC]: {
        mobile: `${config.staticPrefix}/static/ico/favicon.silhouette_ac.png`,
        desktop: `${config.staticPrefix}/static/assets/image/logo/logo_silhouetteAc.svg`,
        descriptionKey: 'free_vector_silhouettes',
        logoNoText: `${config.staticPrefix}/static/assets/image/logo/logo_silhouette_no_text.svg`,
      },
      [SERVICE_TYPES.DESIGN_AC]: {
        mobile: `${config.staticPrefix}/static/ico/favicon.design_ac.ico`,
        desktop: `${config.staticPrefix}/static/assets/image/logo/templateAC_logo_white.svg`,
        faqPage: `${config.staticPrefix}/static/assets/image/logo/templateAC_logo_grey.svg`,
        descriptionKey: 'free_design_templates',
        logoNoText: `${config.staticPrefix}/static/assets/image/logo/logo_design_no_text.svg`,
      },
    };
    return logoInfos[serviceType || process.env.SERVICE_TYPE];
  },
  getLanguages() {
    return [
      { key: 'ar', label: 'العَرَبِيَّة' },
      { key: 'bn', label: 'বাঙালি' },
      { key: 'de', label: 'Deutsch' },
      { key: 'en', label: 'English' },
      { key: 'es', label: 'Español' },
      { key: 'fr', label: 'Français' },
      { key: 'it', label: 'Italiano' },
      // {key: 'jp', label: '日本語'},
      { key: 'ko', label: '한국어' },
      { key: 'hi', label: 'हिंदी' },
      { key: 'pl', label: 'Polski' },
      { key: 'pt', label: 'Português' },
      { key: 'th', label: 'ไทย' },
      { key: 'tr', label: 'Türkçe' },
      { key: 'ru', label: 'Русский' },
      { key: 'vi', label: 'Tiếng Việt' },
      { key: 'zh-tw', label: '中文（繁体字）' },
      { key: 'zh-cn', label: '中文（簡体字）' },
      { key: 'jv', label: 'Indonesia' },
    ];
  },
  renderThumbnailImageByFileName: (filename, serviceType = null) => {
    const folderName = filename.slice(0, 2);
    if (serviceType) {
      if (serviceType === 'photo_ac') {
        return `https://thumb.photo-ac.com/${folderName}/${filename}_t.jpeg`;
      }
      if (serviceType === 'illust_ac') {
        return `https://thumb.ac-illust.com/${folderName}/${filename}_t.jpeg`;
      }
      if (serviceType === 'silhouette_ac') {
        return ` https://thumb.silhouette-ac.com/t/${folderName}/${filename}_t.jpeg`;
      }
    }
    if (config.isPhotoAC()) {
      return `https://thumb.photo-ac.com/${folderName}/${filename}_t.jpeg`;
    }
    if (config.isIllustAC()) {
      return `https://thumb.ac-illust.com/${folderName}/${filename}_t.jpeg`;
    }
    if (config.isSilhouetteAC()) {
      return ` https://thumb.silhouette-ac.com/t/${folderName}/${filename}_t.jpeg`;
    }
    return `https://data.designac.co/data/thumbnails/${folderName}/${filename}_0_t.jpeg`;
  },
};

export default config;
export { keyConfig };
